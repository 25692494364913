import { GUID } from "@faro-lotv/ielement-types";
import { decodeJwtPayload, Token } from "@faro-lotv/service-wires";
import { isNumber } from "lodash";

interface IGetToken {
  /** The JWT received from the backend */
  token: Token;
}

interface IJWTokenRequestPayload {
  /** The scopes the JWT has to provide for the user */
  scopes: string[];
  data: {
    projects?: Array<{
      /** ID of the project to work with */
      id: GUID;
    }>;
    companies?: Array<{
      /** ID of the project to work with */
      id: GUID;
    }>;
  };
}

interface CoreApiTokenManagerParams {
  /** URL of the HB Core API */
  coreApiUrl: URL;

  /** ID of the company the user wants to access */
  companyId?: GUID;

  /** ID of the project the user wants to access */
  projectId?: GUID;

  /** A string to identify a backend client in the format client/version */
  clientId?: string;

  /** The scopes that we're requesting a token for. */
  scopes: string[];
}

const oneSecondInMilliseconds = 1000;
const oneMinuteInSeconds = 60;
const oneMinuteInMs = oneMinuteInSeconds * oneSecondInMilliseconds;

/**
 * Responsible for managing authentication tokens from the Core API.
 *
 * An authentication with the Core API is needed for practically all Holobuilder/Sphere XG backends.
 * This manager is a replicate of token manager from lotV service wires
 * with possibility to request token with or without company id, project id.
 *
 * The manager caches the current JWT and requests a new token if the current one is expired.
 */
export class CoreApiTokenManager {
  /** Cached authentication token to reuse for future requests. */
  #token: Token | undefined;

  /**
   * Cached request to request a new token.
   * We save this to avoid requesting multiple tokens at the same time.
   */
  #tokenRequest: Promise<Token> | undefined;

  /** Payload to use when requesting a new token */
  #tokenRequestPayload: IJWTokenRequestPayload;

  /**
   * The session token in the case we are logged in with SSO
   * Please check: https://faro01.atlassian.net/browse/JWA3-813
   */
  #sessionToken: Token | undefined;

  #coreApiUrl: URL;

  #clientId?: string;

  /**
   * Number of seconds that the browser clock appears to be behind of the server clock.
   * This is important to measure because isTokenValid() relies on the browser clock.
   */
  #browserClockBehindCoreApi: number | undefined;

  /**
   * Creating a TokenManager instance
   */
  constructor(params: CoreApiTokenManagerParams) {
    this.#coreApiUrl = params.coreApiUrl;
    this.#clientId = params.clientId;
    this.#tokenRequestPayload = {
      scopes: params.scopes,

      data: {
        ...(params.projectId && { projects: [{ id: params.projectId }] }),
        ...(params.companyId && { companies: [{ id: params.companyId }] }),
      },
    };
  }

  /**
   * @returns Number of seconds that the browser clock appears to be behind of the server clock.
   * This is important to measure because isTokenValid() relies on the browser clock.
   */
  get browserClockBehindCoreApi(): number | undefined {
    return this.#browserClockBehindCoreApi;
  }

  /**
   * Setting a sessionToken for usage when an SSO login has taken place.
   */
  set sessionToken(sessionToken: Token | undefined) {
    this.#sessionToken = sessionToken;
  }

  /**
   * @returns Check if the given token is valid
   * @param token The token to check for validity
   * The token is considered valid if it is present and it does not expire in the next minute
   */
  private isTokenValid(token: Token): boolean {
    const decodedToken = decodeJwtPayload(token);
    // This logic breaks if this.#browserClockBehindCoreApi > 60 seconds: We would assume that the
    // token is still valid, while in fact it's already expired.
    // It also breaks if the browser clock is ahead of the server clock, and the inaccuracy so big
    // that it's close to the token validity duration. Then new tokens would get requested all the time.
    return (
      !!decodedToken.exp &&
      decodedToken.exp * oneSecondInMilliseconds - Date.now() >= oneMinuteInMs
    );
  }

  /**
   * @returns A valid token to make requests to the Project API
   *
   * Will request a new token if there is none yet or the current one is not valid anymore
   */
  public getToken(): Promise<Token> {
    const cachedToken = this.#token;

    // Return the cached token if we have one and it is valid
    if (cachedToken && this.isTokenValid(cachedToken)) {
      return Promise.resolve(cachedToken);
    }

    // If we already have a request running to fetch a new token, return that
    if (this.#tokenRequest) {
      return Promise.resolve(this.#tokenRequest);
    }

    // Otherwise, create a new request
    this.#tokenRequest = this.requestNewToken(this.#tokenRequestPayload)
      // Reset the cached request on completion
      // It will have set `this.#token` that we can reuse next time
      .finally(() => {
        this.#tokenRequest = undefined;
      });

    return this.#tokenRequest;
  }

  /**
   * Requesting a new token for the current user with the provided payload
   *
   * @param tokenPayload The payload to use for requesting the JWT
   * @returns the JWT
   */
  private async requestNewToken(
    tokenPayload: IJWTokenRequestPayload
  ): Promise<Token> {
    const tokenUrl = new URL("v3/auth/token", this.#coreApiUrl);

    const headers = new Headers({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "Content-Type": "application/json",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ...(this.#clientId && { "X-Holobuilder-Component": this.#clientId }),
    });

    let credentials: RequestCredentials = "include";

    // Add the session token to the headers if it exists and avoid including the already set credentials in that case
    if (this.#sessionToken) {
      headers.append("Cookie", `JSESSIONID=${this.#sessionToken}`);
      credentials = "omit";
    }

    const resp = await fetch(tokenUrl.toString(), {
      method: "post",
      headers,
      body: JSON.stringify(tokenPayload),

      // Making sure to include any available session cookies
      // E.g. from the Dashboard while being embedded in it,
      // but only if the session token is not set
      credentials,
    });

    if (!resp.ok) {
      throw Error(
        `TokenManager: requestNewToken failed with "${resp.statusText}" (${resp.status})`
      );
    }

    const respData: IGetToken = (await resp.json()).data;

    const { token } = respData;
    this.#token = token;
    this.tryMeasureClockSkew(token);
    return token;
  }

  /** Compare token-issued-at (iat) with browser clock. */
  public tryMeasureClockSkew(token: string): void {
    try {
      const { iat } = decodeJwtPayload(token);
      if (isNumber(iat)) {
        // The value is not 100% exact due to the network round trip of the token request.
        this.#browserClockBehindCoreApi = iat - (Date.now() / oneSecondInMilliseconds);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- Optional functionality, but developers want to know if it's broken.
      console.error("tryMeasureClockSkew", error);
    }
  }
}
