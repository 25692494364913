import { TokenProvider } from "@faro-lotv/service-wires";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { CLIENT_ID } from "@api/client-id";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports -- One of the central places to import CoreApiTokenManager.
import { CoreApiTokenManager } from "@api/core-api-token-manager";

/** Map from stringified project ID to cached { token manager, token provider }. */
const tokenProviderMap: Record<string, { manager: CoreApiTokenManager; provider: TokenProvider }> = {};

/**
 * Returns a token provider for the given project ID. The instance is cached, so that the /auth/token endpoint
 * will be called only once per project as long as the token is valid.
 * @param projectId
 * @returns Function that returns a Promise for a token.
 */
export function getCachedTokenProvider(projectId: APITypes.ProjectId): TokenProvider {
  projectId = projectId.toString();
  if (!tokenProviderMap[projectId]) {
    const coreApiUrl = new URL(runtimeConfig.urls.apiBaseUrl);
    // We don't need to include a company ID, and we use same scope as the LotV token manager.
    const tokenManager = new CoreApiTokenManager({
      coreApiUrl,
      projectId,
      clientId: CLIENT_ID,
      scopes: ["user:project"],
    });
    tokenProviderMap[projectId] = {
      manager: tokenManager,
      provider: tokenManager.getToken.bind(tokenManager),
    };
  }
  return tokenProviderMap[projectId].provider;
}

/**
 * Returns a token provider for the given project ID. The instance is cached, so that the /auth/token endpoint
 * will be called only once per project as long as the token is valid.
 * @param projectId
 * @returns Function that returns a Promise for a token.
 */
export function useCachedTokenProvider({
  projectId,
}: BaseProjectIdProps): TokenProvider {
  // Make sure the client is only created once, unless projectId changes.
  return useMemo(
    () => getCachedTokenProvider(projectId),
    [projectId]
  );
}

/**
 * @param projectId
 * @returns Number of seconds that the browser clock appears to be behind of the server clock,
 * according to the "iat" claim of the token.
 */
export function getBrowserClockBehindCoreApi(projectId: APITypes.ProjectId): number | undefined {
  projectId = projectId.toString();
  return tokenProviderMap[projectId]?.manager.browserClockBehindCoreApi;
}
