import { TypedEvent, assert, exponentialBackOff, retry, GUID } from "@faro-lotv/foundation";
import {
  BackgroundTaskState,
  ChunkUploadRequestDescription,
  UploadFailedError,
} from "@faro-lotv/service-wires";
import { ApiClient } from "@stellar/api-logic";
import { ProjectId } from "@stellar/api-logic/dist/api/core-api/api-types";
import { IChunkUploadResponse } from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { checkMagicFileHeader, uploadedFileHash } from "@utils/file-utils";
import {
  DEFAULT_CHUNK_SIZE,
  MAX_CONCURRENT_CHUNKS,
} from "@custom-types/upload-manager-types";
import pLimit from "p-limit";
import { ChunkedMD5 } from "@utils/chunked-md5";
import { delay } from "@utils/time-utils";
import { getCachedTokenProvider } from "@api/use-cached-token-provider";
import { runtimeConfig } from "@src/runtime-config";
import { ExistingFileToReuse, ExistingFilesToReuse, SdbFinalizerCallback } from "@custom-types/file-upload-types";

const SMALLEST_ERROR_CODE = 300;
const HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE = 431;

// For the first i = [0 ... MAX_CONCURRENT_CHUNKS - 1] chunks, we wait (i * CHUNK_UPLOAD_DELAY) milliseconds.
// This is intended to better utilize the available bandwidth, since then not all chunks are waiting for the
// OPTIONS request at the same time.
const CHUNK_UPLOAD_DELAY = 200;

/** Error that occurred most likely because of too large request headers, with too many cookies. */
export const COOKIES_ERROR_MESSAGE = "Please try clearing your browser cookies.";

/** Upload endpoint: Auto (prefer direct storage upload if available), Frontdoor or Storage. */
export type UploadEndpoint = "auto" | "frontdoor" | "storage";

/**
 * Error-like object thrown e.g. by generateChunkUploadData().
 * @stellar/api-logic `Error.BackendError` is missing the `status` field, and adds the mandatory `data` field
 * that doesn't exist in our case.
 */
interface CoreApiError {
  status: number;
  // These are also present, but we don't need them yet:
  // code: string;
  // error: string;
  // errorCode: string;
  // error_v2: string;
  // message: string;
  // requestId: string;
}

/**
 * @param a AbortSignal or undefined
 * @param b AbortSignal
 * @returns A combined AbortSignal (aborted if any of them is aborted),
 *          or the first defined one of the two if AbortSignal.any is not available.
 */
function abortSignalAnyOrFirst(a: AbortSignal | undefined, b: AbortSignal): AbortSignal {
  if (a && AbortSignal.any) {
    return AbortSignal.any([a, b]);
  } else {
    return a || b;
  }
}

/**
 * A class to upload a file to a given holobuilder project through the Core API.
 * Useful to e.g. upload a point cloud to a project.
 *
 * Possible future developments of this class:
 * * Add support for retrying uploads of single chunks when they fail for timeout
 * * Add support for retrying the whole upload
 * * Improve error handling since first two API calls may also fail
 * * Add support for interrupting and resuming deliberately an upload.
 */
export class CoreFileUploader {
  static #maxConcurrentChunks = MAX_CONCURRENT_CHUNKS;
  static #uploadEndpoint: UploadEndpoint = "auto";
  static #uploadEndpointPromise: Promise<UploadEndpoint> | null = null;

  #file: File;
  #projectId: ProjectId;
  #coreApi: ApiClient;
  #bytesUploaded = 0;
  // Set to performance.now() before first chunk is uploaded.
  #timeStartChunks = 0;
  #progress = 0;
  #state: BackgroundTaskState = BackgroundTaskState.created;
  #chunkSize: number = DEFAULT_CHUNK_SIZE;
  #chunkLimiter = pLimit(CoreFileUploader.#maxConcurrentChunks);
  // Control a Signal to abort the in-progress chunk uploads when an error occurs.
  #chunkAbortController: AbortController = new AbortController();

  /** Event emitted when the upload progress advanced. Argument is the current progress from 0 to 100. */
  progressChanged = new TypedEvent<{
    // Progress in percent from 0 to 100.
    progress: number;
    // Expected end time in milliseconds since the epoch.
    expectedEnd: number | undefined;
    // Upload speed in MBytes per second.
    speedMBps: number;
  }>();

  /**
   * Event emitted when the upload completed.
   * The argument is the URL at which the file can be downloaded and the md5 hash of the file.
   */
  uploadCompleted = new TypedEvent<{ downloadUrl: string; md5: string }>();

  /** Event emitted when the upload fails. The argument conveys available information about the upload error. */
  uploadFailed = new TypedEvent<Error>();

  /** Event emitter when the upload is canceled */
  uploadCanceled = new TypedEvent<undefined>();

  /**
   *
   * @param file The file to upload
   * @param projectId The ID of the project to upload the file to
   * @param coreApi The Core API client to be used for uploading.
   */
  constructor(file: File, projectId: ProjectId, coreApi: ApiClient) {
    this.#file = file;
    this.#projectId = projectId;
    this.#coreApi = coreApi;
  }

  /**
   * Set the maximum number of chunks that can be uploaded concurrently, both for future uploads,
   * and for the given existing instances.
   * @param maxChunks Integer >= 1.
   * @param instances Optional list of existing CoreFileUploader instances.
   */
  static setMaxConcurrentChunks(maxChunks: number, instances?: CoreFileUploader[]): void {
    assert(Number.isInteger(maxChunks) && maxChunks >= 1);
    // Adapt limit for future uploads:
    CoreFileUploader.#maxConcurrentChunks = maxChunks;
    // Adapt limit for current uploads:
    for (const instance of instances ?? []) {
      instance.#chunkLimiter.concurrency = maxChunks;
    }
  }

  /** Set the upload endpoint to use. */
  static setUploadEndpoint(endpoint: UploadEndpoint): void {
    assert(endpoint === "auto" || endpoint === "frontdoor" || endpoint === "storage");

    CoreFileUploader.#uploadEndpoint = endpoint;
    if (endpoint === "auto") {
      // Make sure that #determineUploadEndpoint() is called again.
      CoreFileUploader.#uploadEndpointPromise = null;
    } else {
      // For completeness, set the Promise to the same value.
      CoreFileUploader.#uploadEndpointPromise = Promise.resolve(endpoint);
    }
  }

  /**
   * After max. 12 seconds in total, determines if direct upload to Azure Blob Storage is possible.
   * For some customers, the requests to "*.windows.net" might be blocked by their DNS-based firewall.
   * Frontdoor uses a "*.holobuilder.(com|eu)" URL, which makes success more likely, and it's also the URL
   * that was used all the time, so customers are likely to have it whitelisted.
   *
   * Our assumption is that requests to Azure Storage:
   * - go through fine (with timeout and max. 1 retry), or
   * - are blocked by the firewall on the DNS lookup, or
   * - are redirected by the firewall on the DNS lookup (which may result in an HTTPS error), or
   * - are blocked when trying to connect to the server.
   *
   * @returns "storage" for direct upload, or "frontdoor" for upload via Frontdoor.
   *          The returned Promise always resolves; all errors are caught.
   */
  static async #determineUploadEndpoint(): Promise<UploadEndpoint> {
    const MAX_TRIES = 2;
    const RETRY_DELAY_INITIAL = 2000;
    const TIMEOUT = 5000;

    for (let i = 0; i < MAX_TRIES; i++) {
      try {
        const signal = AbortSignal.timeout(TIMEOUT);
        // The query string is added to indicate an expected error, and to make it easier to find the code
        // that sends the request.
        // We make a PUT request since PUT is also used for the chunk uploads.
        const res = await fetch(`${runtimeConfig.urls.uploadStorageUrl}/?faroPingExpected400`, {
          method: "PUT",
          signal,
        });
        const resText = await res.text();
        // We expect a 400 error with XML body.
        // By checking for several expected strings, we try to make the check more robust.
        // Using "<Error" so that it matches both "<Error>" and "<Error someAttr="...">".
        const hasExpectedBody = resText.includes("InvalidQueryParameterValue") ||
          (resText.includes("<Error") && resText.includes("<Code"));
        // Search for e.g. "x-ms-request-id". That header is exposed through CORS and accessible.
        const hasExpectedHeader =  [...res.headers.keys()]
          .some((headerName) => headerName.toLowerCase().startsWith("x-ms-"));
        if (hasExpectedBody || hasExpectedHeader) {
          // The response that we received is most likely from Azure Blob Storage.
          // -> We assume that uploading directly to the storage is possible.
          return "storage";
        }
      } catch (error) {
        // NOP - try again or give up.
      }
      if (i < MAX_TRIES - 1) {
        await delay((i + 1) * RETRY_DELAY_INITIAL);
      }
    }
    return "frontdoor";
  }

  /**
   * Calls #determineUploadEndpoint() only once, and then caches the result.
   * It also caches the Promise, to avoid that two concurrent checks are performed.
   *
   * @returns "storage" for direct upload, or "frontdoor" for upload via Frontdoor.
   *          The returned Promise always resolves; all errors are caught.
   */
  static async getUploadEndpoint(): Promise<UploadEndpoint> {
    if (CoreFileUploader.#uploadEndpoint !== "auto") {
      return CoreFileUploader.#uploadEndpoint;
    } else if (CoreFileUploader.#uploadEndpointPromise) {
      return CoreFileUploader.#uploadEndpointPromise;
    }

    CoreFileUploader.#uploadEndpointPromise = CoreFileUploader.#determineUploadEndpoint()
      .then((endpoint) => CoreFileUploader.#uploadEndpoint = endpoint);
    return await CoreFileUploader.#uploadEndpointPromise;
  }

  /**
   * On cancellation, updates the state and emits the uploadCanceled event.
   *
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @returns Whether the upload has been canceled by the user.
   */
  #checkCanceled(signal?: AbortSignal): boolean {
    // We don't check `this.#chunkAbortController.signal` here, since it's used for errors, not for cancellation.
    const { aborted, failed, succeeded } = BackgroundTaskState;
    if (signal?.aborted && this.#state !== aborted && this.#state !== failed && this.#state !== succeeded) {
      this.#state = BackgroundTaskState.aborted;
      this.uploadCanceled.emit(undefined);
    }
    return this.#state === BackgroundTaskState.aborted;
  }

  /**
   * Upload a single chunk to the backend
   *
   * @param chunk to upload
   * @param spark buffer to compute entire file MD5
   * @param combinedSignal Combined signal for (user abort || one of the chunks failed)
   * @param signal Signal for user abort
   * @returns True if the chunk was uploaded successfully. False if the upload was aborted.
   * @throws {Error} If the upload failed.
   */
  private async uploadChunk(
    chunk: ChunkUploadRequestDescription,
    spark: ChunkedMD5,
    combinedSignal: AbortSignal,
    signal: AbortSignal | undefined
  ): Promise<boolean> {
    // upload chunk
    const startByte = chunk.bytes.start;
    const endByte = chunk.bytes.start + chunk.bytes.length;
    const blob = this.#file.slice(startByte, endByte);
    const blobData = await blob.arrayBuffer();
    spark.append(blobData, startByte);

    // Check cancellation, or error in another chunk, for early return.
    if (this.#checkCanceled(signal) || this.#state === BackgroundTaskState.failed) {
      return false;
    }

    const chunkUrl = CoreFileUploader.#uploadEndpoint === "storage" ?
      chunk.url.replace(runtimeConfig.urls.uploadFrontDoorUrl, runtimeConfig.urls.uploadStorageUrl) :
      chunk.url;

    const response = await retry(
      () =>
        fetch(chunkUrl, {
          method: chunk.method,
          body: blobData,
          headers: {
            ...chunk.headers,
          },
          signal: combinedSignal,
        }),
      {
        max: 5,
        delay: exponentialBackOff,
      }
    );
    // Check cancellation for early return.
    // If another chunk has already failed, we must not report further progress,
    // otherwise the file would be again shown as in-progress in the UI.
    if (this.#checkCanceled(signal) || (this.#state as BackgroundTaskState) === BackgroundTaskState.failed) {
      return false;
    }
    // handle error
    if (!response.ok || response.status >= SMALLEST_ERROR_CODE) {
      throw new UploadFailedError(
        response.status,
        response.statusText,
        startByte,
        this.#file.size
      );
    }
    // progress 1 - update members
    this.#bytesUploaded += chunk.bytes.length;
    this.#progress = Math.floor((this.#bytesUploaded * 100) / this.#file.size);
    // progress 2 - emit event
    this.#reportProgress();

    return true;
  }

  /** Emit progress event, e.g. for the "cloud menu" and Staging Area progress. */
  #reportProgress(): void {
    const msecElapsed = performance.now() - this.#timeStartChunks;
    const bytes = this.#bytesUploaded;
    if (msecElapsed <= 0 || bytes <= 0) {
      return;
    }

    const speed = bytes / msecElapsed;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- (1024 * 1024) = bytes in MBytes.
    const speedMBps = bytes / (1024 * 1024) / (msecElapsed / 1000);
    const remainingTime = (this.#file.size - bytes) / speed;
    const expectedEnd = Date.now() + remainingTime;

    this.progressChanged.emit({ progress: this.#progress, expectedEnd, speedMBps });
  }

  /**
   * Finalize the upload to the backend
   *
   * @param id GUID of upload task
   * @param chunkUploadDescription the descriptor for the entire upload
   * @param spark buffer to compute entire file MD5
   * @param signal to abort the upload
   * @param finalizer function to commit this upload to the project
   */
  private async finalizeUpload(
    uploadTaskId: GUID,
    chunkUploadDescription: IChunkUploadResponse,
    spark: ChunkedMD5,
    signal?: AbortSignal,
    finalizer?: SdbFinalizerCallback
  ): Promise<void> {
    // Finalize
    const { finalize } = chunkUploadDescription;
    const finalizeUrl = CoreFileUploader.#uploadEndpoint === "storage" ?
      finalize.url.replace(runtimeConfig.urls.uploadFrontDoorUrl, runtimeConfig.urls.uploadStorageUrl) :
      finalize.url;

    const ret = await fetch(finalizeUrl, {
      method: finalize.method,
      headers: { ...finalize.headers },
      body: finalize.body,
      signal,
    });

    // check cancellation
    if (this.#checkCanceled(signal)) {
      return;
    }
    // check error
    if (!ret.ok || ret.status >= SMALLEST_ERROR_CODE) {
      throw new UploadFailedError(
        ret.status,
        ret.statusText,
        this.#file.size,
        this.#file.size
      );
    }

    const { downloadUrl } = chunkUploadDescription;
    const md5 = spark.end();
    await this.completeUpload(uploadTaskId, downloadUrl, md5, finalizer);
  }

  /** Tries to call the finalizer function (if set), and if not failed, emits the uploadCompleted event. */
  private async completeUpload(
    uploadTaskId: GUID,
    downloadUrl: string,
    md5: string,
    finalizer?: SdbFinalizerCallback
  ): Promise<void> {
    if (finalizer) {
      await finalizer({
        id: uploadTaskId,
        fileName: this.#file.name,
        fileSize: this.#file.size,
        fileType: this.#file.type,
        downloadUrl,
        md5,
      });
    }

    // Finished!
    this.#state = BackgroundTaskState.succeeded;
    this.uploadCompleted.emit({
      downloadUrl,
      md5,
    });
  }

  /**
   * @returns Properties of file with same name, size and MD5 hash to re-use instead of uploading again.
   *          Undefined if no such file exists.
   */
  async findExistingFileToReuse(
    existingFilesToReuse: ExistingFilesToReuse,
    signal?: AbortSignal
  ): Promise<ExistingFileToReuse | undefined> {
    const hash = uploadedFileHash(this.#file.name, this.#file.size);
    const existingFile = existingFilesToReuse[hash];
    if (!existingFile) {
      return undefined;
    }

    const newMd5 = await ChunkedMD5.fromFile(this.#file, signal);
    return newMd5 === existingFile.md5 ? existingFile : undefined;
  }

  /**
   * Performs the uploading. This function does not throw any exceptions,
   * all exceptions are caught internally and sent via the 'uploadFailed' signal.
   *
   * @param id Id of the upload task 
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @param finalizer An optional function to call to finalize an upload, if it fails the upload is considered failed
   */
  async doUpload(
    id: GUID,
    signal?: AbortSignal,
    finalizer?: SdbFinalizerCallback,
    existingFilesToReuse?: ExistingFilesToReuse
  ): Promise<void> {
    if (
      this.#state !== BackgroundTaskState.created &&
      this.#state !== BackgroundTaskState.scheduled
    ) {
      return;
    }

    this.#state = BackgroundTaskState.started;
    // This changes the state from "scheduled" to "uploading".
    this.progressChanged.emit({ progress: 0.00001, expectedEnd: undefined, speedMBps: 0 });

    let hasChunks = false;
    try {
      // A failed check here is currently not logged to Sentry.
      await checkMagicFileHeader(this.#file);

      // Trigger detection of upload endpoint, if not already started.
      // We should wait for the result, since making the generateChunkUploadData() requests in parallel
      // can lead to a timeout here, which would cause a fallback to Frontdoor.
      await CoreFileUploader.getUploadEndpoint().catch(() => undefined);

      // Get token for project
      const tokenProvider = getCachedTokenProvider(this.#projectId);
      const token = await tokenProvider();

      // check cancellation
      if (this.#checkCanceled(signal)) {
        return;
      }

      if (existingFilesToReuse) {
        const fileToReuse = await this.findExistingFileToReuse(existingFilesToReuse)
          .catch(() => undefined);
        if (fileToReuse) {
          // throws Error if finalizer() fails
          await this.completeUpload(id, fileToReuse.downloadUrl, fileToReuse.md5, finalizer);
          return;
        }

        // check cancellation again, since MD5 calculation takes a while
        if (this.#checkCanceled(signal)) {
          return;
        }
      }

      // Get description on how to split the chunked upload.
      const chunkUploadDescription =
        await this.#coreApi.V3.SDB.generateChunkUploadData({
          projectId: this.#projectId,
          // without the mimetype below, the whole upload does not work.
          contentType: "application/octet-stream",
          downloadName: this.#file.name,
          size: this.#file.size,
          token,
          chunkSize: this.#chunkSize,
        });
      hasChunks = true;
      // check cancellation
      if (this.#checkCanceled(signal)) {
        return;
      }

      // Combined signal for (upload aborted by user || one of the chunks failed).
      // If AbortSignal.any is not supported, we prefer the external `signal` = cancelled by user.
      // In worst case, the in-progress chunks continue uploading after one of the chunks failed.
      const combinedSignal = abortSignalAnyOrFirst(signal, this.#chunkAbortController.signal);

      // initialize MD5 computation
      const spark = new ChunkedMD5();

      // Reference time for progress reports.
      this.#timeStartChunks = performance.now();

      // progressively upload all file chunks
      const chunkUploadPromises: Promise<boolean>[] =
        chunkUploadDescription.chunks.map((chunk: ChunkUploadRequestDescription, idx: number) => {
          return this.#chunkLimiter(async (): Promise<boolean> => {
            // See comment for CHUNK_UPLOAD_DELAY.
            if (idx > 0 && idx < CoreFileUploader.#maxConcurrentChunks) {
              await delay(idx * CHUNK_UPLOAD_DELAY);
            }
            return this.uploadChunk(chunk, spark, combinedSignal, signal);
          });
        });

      // throws Error if at least one of the chunk uploads failed.
      await Promise.all(chunkUploadPromises);

      // throws Error if finalizing the upload with Azure Storage fails
      // throws Error if finalizer() fails
      await this.finalizeUpload(
        id,
        chunkUploadDescription,
        spark,
        combinedSignal,
        finalizer
      );
    } catch (err) {
      // Make sure that no additional chunks are uploaded...
      this.#chunkLimiter.clearQueue();
      // ...and that the requests for any in-progress chunks are aborted.
      this.#chunkAbortController.abort();

      if (this.#checkCanceled(signal)) {
        return;
      }

      this.#state = BackgroundTaskState.failed;
      if (
        (err as CoreApiError | undefined)?.status === HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE ||
        (!hasChunks && typeof err === "string" && err.includes("no response was received"))
      ) {
        // HBCORE-1559: This error is most likely a "431 Request Header Fields Too Large", but we cannot access
        // the response because the required CORS headers are not present.
        // Just in case that it's improved later, we already check for status === 431.
        // "GET /v3/files/signedUrl/chunked" is prone to this error because it includes both the project token
        // and all cookies.
        // On localhost, the error is harder to reproduce because stricter rules apply which cookies are sent.
        // You need to create a large cookie with the same properties (Secure, SameSite, ...) as JSESSIONID.
        this.uploadFailed.emit(
          new Error(COOKIES_ERROR_MESSAGE)
        );
      } else if (err instanceof Error) {
        this.uploadFailed.emit(err);
      } else {
        this.uploadFailed.emit(
          new Error("Upload failed because of an unknown error.")
        );
      }
    }
  }

  /** @returns The upload progress from 0 to 100. */
  get progress(): number {
    return this.#progress;
  }

  /** @returns the file upload state */
  get state(): BackgroundTaskState {
    return this.#state;
  }

  /** Set the chunk size in bytes */
  set chunkSize(chunkSize: number) {
    this.#chunkSize = chunkSize;
  }

  /** @returns the file being uploaded */
  get file(): File {
    return this.#file;
  }
}
