import { GUID } from "@faro-lotv/foundation";
import { ApiClient } from "@stellar/api-logic";
import {
  FileUploadTask,
  FileUploadTaskContext,
  SdbFinalizerCallback,
} from "@custom-types/file-upload-types";
import { UploadEndpoint } from "@utils/core-file-uploader";


/**
 * Too many concurrent uploads can cause high CPU load, and requests might time out.
 * Apparently 32 would be the optimum for a 7 Gbit connection, but that was measured using a native app.
 * Let's keep (MAX_CONCURRENT_UPLOADS * MAX_CONCURRENT_CHUNKS) around 8 or 16.
 * Initial value only; will be auto-adjusted by UploadManager.setMaxConcurrentUploadsAuto().
 */
export const MAX_CONCURRENT_UPLOADS = 8;

/**
 * Sets the maximum no. of chunks uploaded simultaneously within each file.
 * Works together with MAX_CONCURRENT_UPLOADS.
 * Initial value only; will be auto-adjusted by UploadManager.setMaxConcurrentUploadsAuto().
 */
export const MAX_CONCURRENT_CHUNKS = 2;

/** Used for calculations from MiB to bytes and vice versa.  */
export const ONE_MEBIBYTE = 1_048_576;

/** Minimum chunk size in bytes for uploads. Defined by us. Defaults to 1MiB. */
export const MINIMUM_CHUNK_SIZE = ONE_MEBIBYTE;

/** Chunk size in bytes for uploads. Defaults to 5MiB. */
export const DEFAULT_CHUNK_SIZE = 5_242_880;


/**
 * Callback type reporting that the upload has completed successfully
 *
 * @param id The ID the upload task has in the store.
 * @param fileName The name of the file
 * @param fileSize The size of the file
 * @param fileType The type of the file
 * @param downloadUrl The URL of the uploaded file at the remote location
 * @param md5 The hash of the uploaded file
 */
export type UploadCompletedCallback = (
  id: GUID,
  fileName: string,
  fileSize: number,
  fileType: string,
  downloadUrl: string,
  md5: string
) => void;

/**
 * Callback type reporting that the upload has failed
 *
 * @param id is the ID the upload task has in the store.
 * @param fileName is the name of the file.
 * @param error is the exception that was raised and made the upload fail.
 */
export type UploadFailedCallback = (
  id: GUID,
  fileName: string,
  error: Error
) => void;

/**
 * Callback type reporting that the upload has progressed
 *
 * @param id is the ID the upload task has in the store.
 * @param progress is the new progress of the upload, measured from 0 to 100.
 */
export type UploadUpdatedCallback = (id: GUID, progress: number) => void;

/**
 * Callback type reporting that the upload was canceled
 *
 * @param id is the ID the upload task has in the store.
 * @param fileName is the name of the file.
 */
export type UploadCanceledCallback = (id: GUID, fileName: string) => void;

/**
 *  Function used to notify an upload started to the React context to update the store
 *
 * @param id is the id of the task in the store
 * @param file is the handle to the file to upload
 * @param isSilent whether progress toast notifications should be hidden
 * @param context Additional information about the task
 */
export type StartUploadFn = (
  id: GUID,
  file: File,
  isSilent: boolean,
  context: FileUploadTaskContext
) => void;

/**
 * A type collecting the background task's properties that we may want
 * to update in the store
 */
export type UpdateTaskProps = Partial<
  Pick<FileUploadTask, "progress" | "expectedEnd" | "status" | "errorMessage" | "speedMBps">
>;

/** Function used to notify an upload was updated to the React context to update the store */
export type UpdateTaskFn = (id: GUID, propsToUpdate: UpdateTaskProps) => void;

/** Function used to notify an upload finished to the React context to remove the task from the store */
export type RemoveTaskFn = (id: GUID) => void;

/** All the callbacks that can be passed to the upload manager */
export type FileUploadCallbacks = {
  /** Callback called by the upload to finalize an upload. If it fails the upload is considered failed */
  finalizer?: SdbFinalizerCallback;

  /**
   * Function called when a given file upload completes successfully.
   * This is executed after the finalizer.
   */
  onUploadCompleted?: UploadCompletedCallback;

  /** Function called when a given file upload fails */
  onUploadFailed?: UploadFailedCallback;

  /** Function called when the progress of a given upload changes */
  onUploadUpdated?: UploadUpdatedCallback;

  /** Function called when a given file upload is canceled */
  onUploadCanceled?: UploadCanceledCallback;
}

/** Necessary properties without callbacks */
export type FileUploadParamsOnly = {
  /** The file to upload */
  file: File;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;

  /** Whether to hide progress notifications to the user */
  isSilent?: boolean;

  /** Core API client to perform the upload */
  coreApiClient: ApiClient;
};

/** Necessary properties to upload file by upload manager */
export type FileUploadParams = FileUploadCallbacks & FileUploadParamsOnly;

/**
 * The interface of the object contained in the FileUploadsContext.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUploader and useCancelUpload.
 */
export interface UploadManagerInterface {
  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload(params: FileUploadParams): void;

  /**
   * @param id Id of the task to be canceled
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the task existed and was correctly canceled, i.e. was in progress.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore?: boolean): boolean;

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param files Max concurrent files (integer >= 1).
   * @param chunks Max concurrent chunks per file (integer >= 1).
   * @param isManual True if manually setting the concurrency. This will prevent automatic adjustements.
   */
  setMaxConcurrentUploads(files: number, chunks: number, isManual: boolean): void;

  /**
   * Automatically sets the maximum number of concurrent file uploads for the given files.
   * @param files Files to upload.
   */
  setMaxConcurrentUploadsAuto(files: File[]): void;

  /**
   * Set the chunk size for upload in bytes
   * @param value
   */
  setChunkSize(value: number): void;

  /** Set the upload endpoint to use. */
  setUploadEndpoint(endpoint: UploadEndpoint): void;

  /**
   * Returns true if it is a shared worker
   */
  isSharedWorker(): boolean
}

/** Response object for the progress message */
export type ProgressResponseArg = {
  // ID of the upload task.
  id: GUID;
  // Progress in percent from 0 to 100.
  progress: number;
  // Expected end time in milliseconds since the epoch.
  expectedEnd?: number;
  // Upload speed in MBytes per second.
  speedMBps: number;

};

/** Response object for the complete message */
export type CompletedResponseArg = {
  // ID of the upload task
  id: GUID;
  // Name of the uploaded file
  fileName: string,
  // Size of the uploaded file
  fileSize: number,
  // file type
  fileType: string,
  // Url to download the uploaded file from
  downloadUrl: string;
  // md5 hash of the uploaded file
  md5: string,
  // endpoint where the file was uploaded to
  uploadEndpoint: UploadEndpoint;
}
/** Response object for the cailed Mmssage*/
export type FailedResponseArg = { id: GUID, fileName: string, error: Error };

/** Reespone object for the cancleded message */
export type CanceledResponseArg = { id: GUID, fileName: string };

/** Response Messages of the shared worker. They are converted into callbacks */
export type SharedWorkerResponseMessage =
  | { responseType: "onProgress"; id: GUID; arg: ProgressResponseArg }
  | { responseType: "onComplete"; id: GUID; arg: CompletedResponseArg }
  | { responseType: "onError"; id: GUID; arg: FailedResponseArg }
  | { responseType: "onCanceled"; id: GUID; arg: CanceledResponseArg }
  | { responseType: "onConnectTab"; tabId: string }
  | { responseType: "onSetUploadEndpoint"; uploadEndpoint: UploadEndpoint };

/** Define FileUploadParamsReduced by omitting the coreApiClient */
export type FileUploadParamsReduced = Omit<FileUploadParamsOnly, "coreApiClient">;

/** A request message to the shared worker */
export type SharedWorkerRequestMessage =
  | { requestType: "startFileUpload"; id: GUID; params: FileUploadParamsReduced }
  | { requestType: "cancelFileUpload"; id: GUID }
  | { requestType: "heartbeat"; tabId: string }
  | { requestType: "setMaxConcurrentUploads"; value: number }
  | { requestType: "setMaxConcurrentChunks"; value: number }
  | { requestType: "setMaxConcurrentUploadsAuto"; value: number }
  | { requestType: "setChunkSize"; value: number }
  | { requestType: "setUploadEndpoint"; endpoint: UploadEndpoint };

/**
 * Type guard for SharedWorkerRequestMessage
 */
export function isSharedWorkerRequestMessage(obj: SharedWorkerRequestMessage): obj is SharedWorkerRequestMessage {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  switch (obj.requestType) {
    case "startFileUpload":
      return typeof obj.id === "string" && typeof obj.params === "object";
    case "heartbeat":
      return typeof obj.tabId === "string";
    case "cancelFileUpload":
      return typeof obj.id === "string";
    case "setMaxConcurrentUploads":
    case "setMaxConcurrentChunks":
    case "setMaxConcurrentUploadsAuto":
    case "setChunkSize":
      return typeof obj.value === "number";
    case "setUploadEndpoint":
      return typeof obj.endpoint === "string";
    default:
      // This ensures that all cases are handled
      obj satisfies never;
      return false;
  }
}

/**
 * Type guard for SharedWorkerResponseMessage
 */
export function isSharedWorkerResponseMessage(obj: SharedWorkerResponseMessage): obj is SharedWorkerResponseMessage {
  if (typeof obj !== "object" || obj === null || obj === undefined) { return false; }
  if (typeof obj.responseType !== "string") { return false; }

  switch (obj.responseType) {
    case "onProgress":
      return typeof obj.arg === "object" && typeof obj.id === "string";
    case "onComplete":
      return typeof obj.arg === "object" && typeof obj.id === "string";
    case "onError":
      return typeof obj.arg === "object" && typeof obj.id === "string";
    case "onCanceled":
      return typeof obj.arg === "object" && typeof obj.id === "string";
    case "onConnectTab":
      return typeof obj.tabId === "string";
    case "onSetUploadEndpoint":
      return typeof obj.uploadEndpoint === "string";
    default:
      // This ensures that all cases are handled
      obj satisfies never;
      return false;
  }
}